import styled from "styled-components";
import Button from "../button";

type ButtonType = "active" | "inactive";

export const CountDownButton = styled(Button)<{ $type: ButtonType }>(
  (props) => ({
    background: props.$type === "active" ? "#9747FF" : "#FFFFFF1F",
    boxShadow: `0px 1px 1px 0px  ${
      props.$type === "active" ? "#FFFFFF99" : "#FFFFFF1F"
    } inset`,
    padding: ".5rem 0",
    borderRadius: ".5rem",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: "#fff",
    width: "100%",
    border: "0",
    height: "inherit",
  })
);
