import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    padding: 0 1rem;
    display: flex;
    justify-content: center;
  }
  .Toastify__toast {
    border-radius: 0.75rem;
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
    background-color: #3fffff;
  }
  // TODO: differ base on type not theme?
  .Toastify__toast-theme--light {
    background: linear-gradient(
        0deg,
        rgba(151, 71, 255, 0.95),
        rgba(151, 71, 255, 0.95)
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .Toastify__close-button {
    color: white;
    opacity: 1;
    align-self: auto;
  }
  .Toastify__toast-icon {
    height: 2.25rem;
    width: 2.25rem;
  }
`;
