import { useEffect } from "react";
import useSWR from "swr";
import { useSearchParams } from "react-router-dom";
import { IReferrals } from "../interfaces/interfaces";
import { swrSingleGetConfig } from "../configs/swrConfig";

const usePlayerReferrals = () => {
  const [searchParams] = useSearchParams();

  const fetcher = async (url: string, parentRefId: string) => {
    const parentReferralId = localStorage.getItem("refId");

    const response = await fetch(
      `${url}?ParentReferralId=${parentReferralId}`,
      {
        method: "GET",
      }
    );

    return await response.json();
  };

  const { data, mutate, isLoading } = useSWR<IReferrals>(
    `${process.env.REACT_APP_API_URL}/Player/referrals`,
    fetcher,
    swrSingleGetConfig
  );

  useEffect(() => {
    mutate();
  }, [searchParams]);
  return { data, isLoading, fetcher };
};

export default usePlayerReferrals;
