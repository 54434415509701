import { Layout } from "./style";
import { Outlet } from "react-router-dom";

// Pass the child props
export const WebLayout: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
