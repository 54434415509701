import styled from "styled-components";

export const StyledHeader = styled.div({
  height: "3.125rem",
  display: "block",
  width: "calc(100% - 2rem)",
  padding: "1rem",
  color: "white",
  background: "black",
  position: "sticky",
  top: 0,
  borderRadius: "0 0 1rem 1rem",
  zIndex: 9999,
});
