import "./App.css";
import { WebLayout } from "./layouts/webLayout";
import NotAuthorizedPage from "./pages/notAuthorized";
import { BaseLayout } from "./layouts/baseLayout";
import { Routes, Route, Navigate } from "react-router-dom";
import GamesPage from "./pages/app/games";
import PurchasePage from "./pages/app/purchase";
import ReferralsPage from "./pages/app/referrals";
import NotFoundPage from "./pages/notFound";
import LoginPage from "./pages/login";
import WheelPage from "./pages/app/wheel";
import TasksPage from "./pages/app/tasks";
import { useExpand } from "@vkruglikov/react-telegram-web-app";
import { useEffect } from "react";

const App = () => {
  const [, expand] = useExpand();
  useEffect(() => {
    expand();
  }, []);

  return (
    <Routes>
      <Route path="" element={<WebLayout />}>
        <Route index element={<NotAuthorizedPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="auth/*" element={<BaseLayout />}>
          <Route path="tasks" element={<TasksPage />} />
          <Route index path="games" element={<GamesPage />} />
          <Route path="purchase" element={<PurchasePage />} />
          <Route path="wheel" element={<WheelPage />} />
          <Route path="referrals" element={<ReferralsPage />} />
          <Route path="*" element={<Navigate to={"/auth/games"} />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
