import { useEffect, useState } from "react";
import QRCode from "qrcode";

const useGetQrCode = () => {
  const [source, setSource] = useState("");

  const getSource = async (url: string) => {
    try {
      setSource(await QRCode.toDataURL(url));
    } catch (err) {
      // TODO return default QR https://app.casinomanager.io/ -static QR?
      console.error(err);
    }
  };

  useEffect(() => {
    // getSource(window.location.href);
    // For now hardcoded url routing to telegram app - when the mini app is properly deployed we can fetch the current url
    getSource("https://t.me/mini_app_easyplug_bot");
  }, []);

  return { source };
};

export default useGetQrCode;
