import { nanoid } from "nanoid";
import { memo } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { CountDownButton } from "./style";

interface ICountDownProps {
  difference: number;
  onClaim: () => void;
}

const CountDown = memo((props: ICountDownProps) => {
  const { onClaim, difference } = props;

  // Custom renderer function
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      // Render a completed state
      return (
        <CountDownButton
          label="Tap to earn"
          $type="active"
          onClick={() => onClaim()}
        />
      );
    } else {
      // Render the countdown
      return (
        <CountDownButton
          label={`${hours}h : ${minutes}m : ${seconds}s`}
          $type="inactive"
        />
      );
    }
  };
  return (
    <Countdown
      key={nanoid()}
      date={Date.now() + difference}
      renderer={renderer}
    />
  );
});

export default CountDown;
