import { ReactComponent as CoinIcon } from "../../icons/coin-icon-new.svg";

import { formatAmount } from "../../utils/formats";
import {
  Wrapper,
  AmountLabel,
  Label,
  LabelsWrapper,
  Pill,
  IconWrapper,
} from "./style";
import { useMiniAppContext } from "../context";
import { getPicture } from "../../utils/getImage";

const HeaderBar: React.FC = (props) => {
  const context = useMiniAppContext();
  const { coins, level, perHour, userName } = context;
  return (
    <Wrapper>
      <Pill>
        <IconWrapper>
          <img
            src={getPicture("mascot")}
            style={{ width: "34px", height: "34px" }}
            alt="mascot_icon"
          />
        </IconWrapper>
        <LabelsWrapper>
          <Label style={{ color: "#FFA800", marginBottom: "0.125rem" }}>
            {userName}
          </Label>
          <AmountLabel>
            <CoinIcon style={{ marginRight: "0.375rem", color: "#FFA800" }} />
            {formatAmount(coins)}
          </AmountLabel>
        </LabelsWrapper>
      </Pill>
      <Pill>
        <IconWrapper>
          <img
            src={getPicture("dollar")}
            style={{ width: "34px", height: "34px" }}
            alt="dollar_icon"
          />
        </IconWrapper>
        <LabelsWrapper>
          <Label style={{ color: "#FFA800", marginBottom: "0.125rem" }}>
            $ per hour
          </Label>
          <AmountLabel>
            <CoinIcon style={{ marginRight: "0.375rem", color: "#FFA800" }} />
            {formatAmount(perHour)}
          </AmountLabel>
        </LabelsWrapper>
      </Pill>
      <Pill>
        <IconWrapper>
          <img
            src={getPicture("racket")}
            style={{ width: "34px", height: "34px" }}
            alt="rocket_icon"
          />
        </IconWrapper>
        <LabelsWrapper style={{ alignItems: "center", paddingRight: ".25rem" }}>
          <Label style={{ color: "#FFA800", marginBottom: "0.125rem" }}>
            Level
          </Label>
          <AmountLabel>{level}</AmountLabel>
        </LabelsWrapper>
      </Pill>
    </Wrapper>
  );
};

export default HeaderBar;
