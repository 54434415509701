import { getWebpImageUrl } from "../../../utils/getImage";
import WebPImage from "../../webpImage";
import { StyledCard, SubTitle, Title } from "./style";

const CardMoreGames = () => {
  return (
    <StyledCard>
      <Title>{"More games available in shop".toUpperCase()}</Title>
      <SubTitle>Visit the shop to unlock new games and start earning.</SubTitle>
      <WebPImage
        style={{
          height: "7.625rem",
          width: "7.625rem",
          transform: "translate(-25%, 25%)",
          scale: "1.6",
          rotate: "-38.33deg",
        }}
        src={getWebpImageUrl("BlackJack")}
        alt={`game/poker`}
      />
      <WebPImage
        style={{
          height: "7.625rem",
          width: "7.625rem",
          transform: "translate(95%, -50%)",
          scale: "2.2",
        }}
        src={getWebpImageUrl("Dice")}
        alt={`game/dice`}
      />
    </StyledCard>
  );
};

export default CardMoreGames;
