import styled from "styled-components";

export const StyledLayout = styled.div({
  backgroundColor: "#040404",
  display: "flex",
  height: "100svh",
  width: "100vw",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  color: "white",
  overflow: "scroll",
});
