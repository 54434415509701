import { toast } from "react-toastify";
import useSWRMutation from "swr/mutation";
import { getPicture } from "../utils/getImage";

const fetcher = async (url: string, { arg }: { arg: { gameId: string } }) => {
  const telegramId = localStorage.getItem("playerId");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ telegramId: telegramId, ...arg }),
  });

  if (!response.ok) {
    // TODO polish error handling based on the specifics of your API
    // const errorMessage = await response.text();

    // throw new Error("Network response was not ok");
    throw toast.error("Player does not have enough coins.", {
      position: "top-left",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.success("Purchase successful!", {
      position: "top-left",
      icon: (
        <img
          src={getPicture("racket")}
          style={{ width: "34px", height: "34px" }}
          alt="rocket_icon"
        />
      ),
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return response.json();
  }
};

export const usePlayerCreateGame = () => {
  return useSWRMutation(
    `${process.env.REACT_APP_API_URL}/Player/create-player-game`,
    fetcher
  );
};
