import styled from "styled-components";

export const StyledButton = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: "2rem",
  borderRadius: "0.5rem",
  fontSize: ".8rem",
  fontWeight: "bolder",
  cursor: "pointer",
  border: "none",
  width: "100%",
  padding: "0.5rem",
  background: "#9747FF",
  color: "white",
  transition: "background-color 0.3s ease-in-out",
  lineHeight: 1,
  letterSpacing: "0.0116rem",
});
