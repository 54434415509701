import { getWebpImageUrl } from "../../../utils/getImage";
import { PropsWithChildren } from "react";
import Badge from "../../badge";
import { formatAmount, formatMinutes } from "../../../utils/formats";
import { IGame } from "../../../interfaces/interfaces";
import { CountDownButton } from "../../countDown/style";
import _ from "lodash";
import WebPImage from "../../webpImage";
import { StyledCard } from "../style";

interface ICardProps {
  data: IGame;
  isDisabled: boolean;
  onClaim: () => void;
}

const CardPurchaseGame: React.FC<PropsWithChildren & ICardProps> = (props) => {
  const { cost, name, duration, revenue, incomePerHour } = props.data;

  // TODO: replace inline style with styled-components
  return (
    <StyledCard>
      {/* // image background */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.875rem",
          width: "100%",
        }}
      >
        <div
          style={{
            borderRadius: "0.875rem",
            background: "#FFFFFF33",
            height: "9rem",
            width: "7.625rem",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              fontSize: "0.625rem",
              fontWeight: 500,
              lineHeight: "1rem",
              textAlign: "left",
              padding: ".25rem .75rem",
              background: "rgba(255, 255, 255, 0.05)",
              zIndex: 10,
              position: "absolute",

              backdropFilter: "blur(10px)",
              borderBottomRightRadius: ".75rem",
              display: "inline-block",
              verticalAlign: "top",
              letterSpacing: "0.0717rem",
            }}
          >
            {formatMinutes(+duration)}
          </div>
          <WebPImage
            style={{
              height: "9rem",
              width: "7.625rem",
              zIndex: 9,
              position: "absolute",
              transform: "translate(10%, 10%)",
              scale: "1.25",
            }}
            src={getWebpImageUrl(name)}
            alt={`game/${name}`}
          />
        </div>
        <div style={{ display: "inline-block", flex: 1 }}>
          <p
            style={{
              margin: 0,
              fontSize: "1.5rem",
              lineHeight: "1.375rem",
              fontWeight: 600,
              letterSpacing: "0.0116rem",
            }}
          >
            {_.capitalize(name)}
          </p>
          <div
            style={{
              margin: "1rem 0",
              display: "flex",
              gap: "0.375rem",
            }}
          >
            <Badge label="Per hour" coins={+incomePerHour} />
            <Badge label="Revenue" revenue={+revenue} type="revenue" />
          </div>
          <CountDownButton
            disabled={props.isDisabled}
            label={`Buy for ${formatAmount(+cost)}$`}
            $type="active"
            onClick={() => props.onClaim()}
          />
        </div>
      </div>
    </StyledCard>
  );
};

export default CardPurchaseGame;
