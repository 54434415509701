import { Container, Loader } from "./style";

const LoadingSpinner: React.FC = () => {
  return (
    <Container>
      <Loader viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
        />
      </Loader>
    </Container>
  );
};

export default LoadingSpinner;
