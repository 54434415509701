import { StyledButton } from "./style";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

const Button: React.FC<IButtonProps> = (props) => {
  const [, , selectionChanged] = useHapticFeedback();
  return (
    <StyledButton
      onClick={() => {
        selectionChanged();
      }}
      {...props}
    >
      {props.label}
    </StyledButton>
  );
};

export default Button;
