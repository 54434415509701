import { StyledHeader } from "./style";
import HeaderBar from "../headerBar";

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <HeaderBar />
    </StyledHeader>
  );
};

export default Header;
