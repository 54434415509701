import styled from "styled-components";

export const StyledCard = styled.div(
  (props) => ({
    // backgroundImage: ` url(${props.$backgroundImageUrl})`,
  }),
  {
    backgroundColor: "#18181B",
    overflow: "hidden",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    paddingRight: "2.313rem",
    borderRadius: "1rem",
  }
);
