import { toast } from "react-toastify";
import useSWRMutation from "swr/mutation";
import { ISpinWheelResponse } from "../interfaces/interfaces";

const fetcher = async (url: string) => {
  const refId = localStorage.getItem("refId");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: refId }),
  });

  if (!response.ok) {
    // TODO polish error handling based on the specifics of your API
    // const errorMessage = await response.text();

    // throw new Error("Network response was not ok");
    throw toast.error("Something went wrong :(", {
      position: "top-left",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    // TODO: success toast?
    return response.json();
  }
};

export const useSpinWheel = () => {
  return useSWRMutation<ISpinWheelResponse>(
    `${process.env.REACT_APP_API_URL}/Player/spin-wheel`,
    fetcher
  );
};
