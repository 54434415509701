import styled from "styled-components";

export const List = styled.ul({
  display: "flex",
  overflow: "scroll",
  justifyItems: "center",
  flexDirection: "column",
  padding: "1rem",
  paddingTop: "unset",
  gap: "0.875rem",
  margin: "0",
});
