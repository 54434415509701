import styled from "styled-components";

export const Content = styled.div({
  // TODO add styles
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(151, 71, 255, 0.7)",
  borderTopLeftRadius: "1rem",
  borderTopRightRadius: "1rem",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
});
