import usePlayerReferrals from "../../hooks/usePlayerReferrals";
import { ReactComponent as InfoIcon } from "../../icons/info-icon.svg";
import { ReactComponent as LinkIcon } from "../../icons/link-icon.svg";
import TelegramShareButton from "../shareButton";
import { Bounce, toast } from "react-toastify";
import WebPImage from "../webpImage";
import { getPicture, getWebpImageUrl } from "../../utils/getImage";

const HeaderBanner = () => {
  // TODO replace inline styles with styled components
  const { data } = usePlayerReferrals();
  const telegramId = localStorage.getItem("playerId");

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 99,
        background: "#9747FF",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomLeftRadius: "1rem",
        borderBottomRightRadius: "1rem",
        overflow: "hidden",
        width: "inherit",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1.5rem 0 2rem 1rem",
          gap: "0.75rem",
        }}
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: "0.75rem",
            color: "white",
            lineHeight: "1.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.25rem",
            textAlign: "left",
            justifyContent: "flex-start",
            margin: 0,
          }}
        >
          Your friends <InfoIcon />
        </p>
        <p
          style={{
            fontWeight: 900,
            fontSize: "1.75rem",
            lineHeight: "1.375rem",
            color: "white",
            margin: 0,
          }}
        >
          {data?.items.length} FRIENDS
        </p>
        <button
          style={{
            padding: "0.5rem .75rem",
            color: "white",
            background: "rgba(255, 255, 255, 0.16)",
            borderRadius: ".5rem",
            border: "0",
            display: "flex",
            justifyContent: "center",
            gap: "0.375rem",
            alignItems: "center",
          }}
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_SHARE_LINK_URL}?start=referral_${telegramId}`
            );
            toast.info("Invited link copied 🚀", {
              position: "top-right",
              hideProgressBar: false,
              icon: (
                <img
                  src={getPicture("racket")}
                  style={{ width: "36px", height: "36px" }}
                  alt="rocket_icon"
                />
              ),
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            });
          }}
        >
          Copy link to invite <LinkIcon />
        </button>
        {telegramId ? <TelegramShareButton id={telegramId} /> : <></>}
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          position: "relative",
        }}
      >
        <WebPImage
          style={{
            height: "9rem",
            width: "7.625rem",
            zIndex: 9,
            position: "absolute",
            transform: "translate(25%, 25%)",
            scale: "2",
          }}
          src={getWebpImageUrl("Dice")}
          alt="A descriptive alt text"
        />
      </div>
    </div>
  );
};

export default HeaderBanner;
