import styled from "styled-components";

export const StyledCard = styled.div(
  (props) => ({
    // backgroundImage: ` url(${props.$backgroundImageUrl})`,
  }),
  {
    background: "linear-gradient(90deg, #18181B 2.48%, #2C033F 102.77%)",
    overflow: "hidden",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    paddingTop: "2rem",
    borderRadius: "1rem",
    height: "15.875rem",
  }
);
export const Title = styled.p({
  color: "#3FFFFF",
  fontSize: "1.75rem",
  fontWeight: 900,
  lineHeight: "1.8rem",
  margin: "0 0 2rem 0",
  padding: "0",
});
export const SubTitle = styled.p({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  margin: "0",
  padding: "0",
  maxWidth: "14.438rem",
});
