import { ReactComponent as WheelIcon } from "../../icons/wheel-icon.svg";
import { ReactComponent as FileCheckIcon } from "../../icons/file-check-icon.svg";
import { ReactComponent as MarketIcon } from "../../icons/market-icon.svg";
import { ReactComponent as PeopleIcon } from "../../icons/people-icon.svg";
import { ReactComponent as Flame2Icon } from "../../icons/flame2-icon.svg";
import { FooterWrapper, GridItem, IconsGrid, MenuLabel } from "./style";
import { nanoid } from "nanoid";

// TODO refac navigation
const icons = [
  { label: "Casino", icon: Flame2Icon, url: "games" },
  { label: "Tasks", icon: FileCheckIcon, url: "tasks" },
  { label: "Wheel", icon: WheelIcon, url: "wheel" },
  { label: "Referrals", icon: PeopleIcon, url: "referrals" },
  { label: "Shop", icon: MarketIcon, url: "purchase" },
];

const Footer: React.FC = () => {
  // TODO add global color scheme

  return (
    <FooterWrapper>
      <IconsGrid>
        {icons.map((item) => (
          <GridItem
            style={({ isActive }) => {
              return {
                color: isActive ? "#FFA800" : "#8C8C8C",
              };
            }}
            to={item.url}
            key={nanoid()}
          >
            <item.icon />
            <MenuLabel>{item.label}</MenuLabel>
          </GridItem>
        ))}
      </IconsGrid>
    </FooterWrapper>
  );
};

export default Footer;
