import { ReactComponent as SadEmojiIcon } from "../../../../icons/sad-emoji-icon.svg";

const EmptyContentReferralsList = () => {
  return (
    <div
      style={{
        textAlign: "center",
        color: "#646970",
      }}
    >
      <SadEmojiIcon />
      <p style={{ marginTop: "0.875rem" }}>
        We haven’t found any users that joined the game with your invite code.
        Invite friends to receive bonus!
      </p>
    </div>
  );
};

export default EmptyContentReferralsList;
