import { nanoid } from "nanoid";
import { PageWrapper } from "../../style";
import { List } from "./style";
import useGetGames from "../../../hooks/useGetGames";
import LoadingSpinner from "../../../components/loadingSpinner";
import { usePlayerCreateGame } from "../../../hooks/usePlayerCreateGame";
import CardPurchaseGame from "../../../components/cards/cardPurchaseGame";
import { getBackgroundUrl } from "../../../utils/getImage";
import { useMiniAppContext } from "../../../components/context";

const PurchasePage: React.FC = () => {
  // TODO add happy flow reroute if player has enough money
  // TODO connect rest of the props
  const { trigger, isMutating } = usePlayerCreateGame();
  const context = useMiniAppContext();

  const { data, isLoading } = useGetGames();

  const handlePurchase = async (gameId: string) => {
    await trigger({ gameId }).then((response) => {
      context.setCoins(response.coins);
      context.setPerHour(response.incomePerHour);
      context.setLevel(response.level);
    });
  };

  return (
    <PageWrapper>
      <div
        style={{
          background: `linear-gradient(in oklab, rgba(0,0,0, 0.8),rgba(173, 107, 30, 0.4),rgb(173, 107, 30, 0.8) 90%, rgb(173, 107, 30, 0.4)),
             url(${getBackgroundUrl("background2")})`,
          minHeight: "100%",
          backgroundSize: "cover, contain",
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPosition: "bottom, top",
        }}
      >
        {!isLoading && data ? (
          <List>
            {data.items.map((item) => (
              <CardPurchaseGame
                isDisabled={isMutating}
                data={item}
                key={nanoid()}
                onClaim={() => handlePurchase(item.id)}
              />
            ))}
          </List>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </PageWrapper>
  );
};

export default PurchasePage;
