import { PageWrapper } from "../style";

const NotFoundPage: React.FC = () => {
  return (
    <PageWrapper>
      <h3>Not found</h3>
    </PageWrapper>
  );
};

export default NotFoundPage;
