import React, { CSSProperties } from "react";

interface WebPImageProps {
  src: string;
  alt: string;
  style?: CSSProperties; // optional style property
}

const WebPImage: React.FC<WebPImageProps> = ({ src, alt, style }) => {
  return <img src={src} style={{ ...style }} alt={alt} />;
};

export default WebPImage;
