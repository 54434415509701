import numeral from "numeral";

export const formatAmount = (amount: number): string => {
  if (amount < 99999) {
    return amount.toLocaleString("fr-FR", {
      minimumFractionDigits: 0,
    });
  } else {
    return numeral(amount).format(amount < 999999 ? "0a" : "0.00a");
  }
};

export const formatMinutes = (minutes: number): string => {
  const totalSeconds = minutes * 60;
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSecondsAfterHours = totalSeconds % 3600;
  const remainingMinutes = Math.floor(remainingSecondsAfterHours / 60);
  const seconds = remainingSecondsAfterHours % 60;

  return `${hours}h : ${remainingMinutes}m : ${seconds}s`;
};
