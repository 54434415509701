import { ReactComponent as CoinIcon } from "../../icons/coin-icon-new.svg";
import { ReactComponent as FlameIcon } from "../../icons/flame-icon.svg";
import { formatAmount } from "../../utils/formats";

type BadgeType = "exp" | "coins" | "revenue";

interface IBadgeProps {
  type?: BadgeType;
  level?: number;
  coins?: number;
  revenue?: number;
  label?: string;
}

const Badge = (props: IBadgeProps) => {
  const { type = "coins", coins = 0, level = 1, revenue = 0, label } = props;

  const getTitle = () => {
    return type === "exp"
      ? `Level ${level}`
      : formatAmount(type === "revenue" ? revenue : coins);
  };

  const getIcon = () => {
    return type === "exp" ? <FlameIcon /> : <CoinIcon />;
  };

  const color =
    type === "exp" ? "#3FFFFF" : type === "revenue" ? "#9FFF3F" : "#FFA800";

  return (
    <div>
      {label ? (
        <p
          style={{
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: "1rem",
            margin: 0,
            marginBottom: "0.375rem",
            color: "white",
            letterSpacing: "0.0116rem",
          }}
        >
          {label}:
        </p>
      ) : (
        <></>
      )}
      <div
        style={{
          color: color,
          padding: "0.375rem ",
          minWidth: "4.625rem",

          borderRadius: "0.375rem",
          boxShadow: `0 0 0 1px ${color} inset`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.25rem",
          backgroundColor:
            type === "exp"
              ? "#FFA80014"
              : type === "revenue"
              ? "#9FFF3F14"
              : "#FFA80014",
        }}
      >
        {getIcon()}
        <p
          style={{
            fontSize: "0.75rem",
            fontWeight: 500,
            lineHeight: "1rem",
            margin: 0,
            letterSpacing: "0.0116rem",
          }}
        >
          {getTitle()}
        </p>
      </div>
    </div>
  );
};

export default Badge;
