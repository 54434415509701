import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

interface ITelegramShareButtonProps {
  id: string;
}

const TelegramShareButton: React.FC<ITelegramShareButtonProps> = (props) => {
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const shareToTelegram = () => {
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      `${process.env.REACT_APP_SHARE_LINK_URL}?start=referral_${props.id}`
    )}&text=${encodeURIComponent("Play with me here 🚀")}`;
    window.open(telegramShareUrl, "_blank");
  };
  return (
    <button
      onClick={() => {
        selectionChanged();
        shareToTelegram();
      }}
      style={{
        padding: "10px",
        backgroundColor: "#0088CC",
        color: "white",
        border: "none",
        borderRadius: "5px",
      }}
    >
      Share on Telegram
    </button>
  );
};
export default TelegramShareButton;
