export const swrCommandConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  revalidateOnMount: false,
  revalidateIfStale: false,
};

export const swrSingleGetConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  revalidateOnMount: true,
  revalidateIfStale: true,
};

export const swrFetcherGetConfig = {
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  shouldRetryOnError: true,
  revalidateOnMount: true,
  revalidateIfStale: true,
};
