import React, {
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  memo,
} from "react";
import { IPlayerGame } from "../../interfaces/interfaces";

interface IMiniAppContext {
  userName: string;
  setUserName: Dispatch<SetStateAction<string>>;
  coins: number;
  setCoins: Dispatch<SetStateAction<number>>;
  level: number;
  setLevel: Dispatch<SetStateAction<number>>;
  perHour: number;
  setPerHour: Dispatch<SetStateAction<number>>;
  games: IPlayerGame[];
  setGames: Dispatch<SetStateAction<IPlayerGame[]>>;
}

interface Props {
  context: IMiniAppContext;
  children: ReactNode;
}

const MiniAppContext = createContext<IMiniAppContext>({} as IMiniAppContext);

const MiniAppProvider: React.FC<Props> = memo(({ children, context }) => {
  return (
    <MiniAppContext.Provider value={context}>
      {children}
    </MiniAppContext.Provider>
  );
});

const useMiniAppContext = () => {
  const context = useContext<IMiniAppContext>(MiniAppContext);
  if (!context) {
    throw new Error("useMiniAppContext must be used within a MiniAppProvider");
  }
  return context;
};
export { MiniAppProvider, MiniAppContext, useMiniAppContext };
