import styled from "styled-components";
import { StyledCard } from "../style";

export const DynamicStyledCard = styled(StyledCard)<{ $isClaimable: boolean }>(
  (props) => ({
    background: props.$isClaimable
      ? "linear-gradient(90deg, #18181B 2.48%, #2C033F 102.77%)"
      : "#18181B",
  })
);
