import useSWR from "swr";
import { IPlayer } from "../interfaces/interfaces";
import { swrSingleGetConfig } from "../configs/swrConfig";
import { useMiniAppContext } from "../components/context";

const usePlayer = () => {
  const context = useMiniAppContext();
  const fetcher = async (url: string) => {
    const telegramId = localStorage.getItem("playerId");
    const response = await fetch(`${url}?TelegramId=${telegramId}`, {
      method: "GET",
    });
    // localStorage.setItem("refId", response;

    const result = await response.json();
    localStorage.setItem("refId", result.id);

    context.setUserName(result.userName);
    context.setCoins(result.coins);
    context.setPerHour(result.incomePerHour);
    context.setLevel(result.level);
    context.setGames(result.playerGames);
    return result;
  };

  const { data, mutate, isLoading } = useSWR<IPlayer>(
    `${process.env.REACT_APP_API_URL}/Player/telegram`,
    fetcher,
    swrSingleGetConfig
  );
  return { data, isLoading, mutate };
};

export default usePlayer;
