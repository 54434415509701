import QrCode from "../../components/qrCode";
import { QrCodeWrapper } from "./style";

const NotAuthorizedPage: React.FC = () => {
  return (
    <QrCodeWrapper>
      <h4>Scan me to continue</h4>
      <QrCode />
    </QrCodeWrapper>
  );
};

export default NotAuthorizedPage;
