import SoonBanner from "../../../components/soonBanner";
import { PageWrapper } from "../../style";

const TasksPage: React.FC = () => {
  return (
    <PageWrapper>
      <SoonBanner pageName="Tasks" />
    </PageWrapper>
  );
};

export default TasksPage;
