import { nanoid } from "nanoid";
import CardGame from "../../../components/cards/cardGame";
import { PageWrapper } from "../../style";
import { List, ListItem } from "./style";
import LoadingSpinner from "../../../components/loadingSpinner";
import { getBackgroundUrl } from "../../../utils/getImage";
import CardMoreGames from "../../../components/cards/cardMoreGames";
import Button from "../../../components/button";
import { useNavigate } from "react-router";
import { useMiniAppContext } from "../../../components/context";

const GamesPage: React.FC = () => {
  const navigate = useNavigate();
  const context = useMiniAppContext();

  return (
    <PageWrapper>
      <div
        style={{
          background: `linear-gradient(in oklab, rgba(0,0,0, 0.8),rgba(40, 0, 91, 0.4),rgb(40, 0, 91) 90%, rgb(40, 0, 91, 0.4)),
             url(${getBackgroundUrl("background2")})`,
          backgroundSize: "cover, contain",
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPosition: "bottom, top",
          minHeight: "100%",
        }}
      >
        {context.games ? (
          <List>
            {context.games.map((item) => (
              <CardGame data={item} key={nanoid()} />
            ))}
            <ListItem>
              <CardMoreGames />
              <Button
                style={{
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                  margin: 0,
                  padding: "0.5rem",
                }}
                onClick={() => navigate("/auth/purchase")}
                label="Go to Shop"
              />
            </ListItem>
          </List>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </PageWrapper>
  );
};

export default GamesPage;
