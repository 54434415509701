import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const FooterWrapper = styled.div({
  display: "block",
  background: "#18181B",
  position: "sticky",
  bottom: 0,
  borderRadius: "1rem 1rem 0 0",
  zIndex: 9999,
  width: "inherit",
});
export const IconsGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr ",
  gridTemplateRows: "auto",
  alignItems: "end",
  padding: ".75rem 0 1rem 0",
  width: "inherit",
});
export const GridItem = styled(NavLink)({
  display: "flex",
  textDecoration: "none",
  gap: "0.25rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
export const MenuLabel = styled.span({
  fontSize: "0.75rem",
  lineHeight: "1rem",
  fontWeight: 500,
});
