import { FC, ReactNode, useEffect } from "react";
import { StyledLayout } from "./style";
import usePlayer from "../hooks/usePlayer";

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { mutate } = usePlayer();

  useEffect(() => {
    mutate();
  }, []);

  return <StyledLayout>{children}</StyledLayout>;
};
