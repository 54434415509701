import { StyledCard } from "./style";
import { PropsWithChildren } from "react";
import Badge from "../../badge";
import { IReferral } from "../../../interfaces/interfaces";
import { getAvatarPicture } from "../../../utils/getImage";

interface ICardProps {
  data: IReferral;
}

const CardRefferal: React.FC<PropsWithChildren & ICardProps> = (props) => {
  const { childReferral } = props.data;
  const { userName, coins, level } = childReferral;

  // TODO: connect api data + add icons to cdn

  return (
    <StyledCard>
      <div
        style={{
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1rem",
          textAlign: "left",
          padding: ".25rem .75rem",
          background: "rgba(255, 255, 255, 0.05)",
          color: "rgba(100, 105, 112, 1)",
          width: "fit-content",
          display: "flex",
          backdropFilter: "blur(10px)",
          borderBottomRightRadius: ".75rem",
        }}
      >
        Invited 29 Jun, 24
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", padding: " 0.875rem" }}
      >
        <div
          style={{
            marginRight: "0.875rem",
          }}
        >
          {/* TODO: Randomize  */}
          <img src={getAvatarPicture("user")} alt="avavar" />
        </div>
        <div style={{ display: "inline-block", marginRight: "auto" }}>
          <p
            style={{
              margin: 0,
              fontSize: "1.5rem",
              lineHeight: "1.375rem",
              fontWeight: 900,
            }}
          >
            {userName.toUpperCase()}
          </p>
          <div
            style={{
              marginTop: ".375rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr ",
              gridTemplateRows: "auto",
              gap: "0.375rem",
            }}
          >
            <Badge coins={coins} />
            <Badge level={level} type="exp" />
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default CardRefferal;
