import styled from "styled-components";

export const LabelsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});
export const Wrapper = styled.div({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#18181B",
  padding: "0.25rem",
  borderRadius: "1.5rem",
  justifyContent: "space-between",
  gap: "1rem",
});

export const Pill = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});
export const IconWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#040404",
  borderRadius: "1.5rem",
  height: "2.625rem",
  width: "2.625rem",
});

export const Label = styled.label({
  display: "flex",
  alignItems: "center",
  padding: "0 0.25rem",
  fontSize: ".75rem",
  fontWeight: 500,
  lineHeight: "1.024rem",
  textAlign: "center",
});

export const AmountLabel = styled(Label)({
  fontSize: "0.875rem",
  lineHeight: "1.195rem",
});
