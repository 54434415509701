import { getWebpImageUrl } from "../../utils/getImage";
import WebPImage from "../webpImage";
import { Content } from "./style";

interface ISonnBannerProps {
  pageName: string;
}

const SoonBanner: React.FC<ISonnBannerProps> = (props) => {
  return (
    <Content>
      <WebPImage
        style={{
          width: "372.54px",
          height: "372.54px",
          top: "-140px",
          right: "99px",
          transform: "rotate(-83.39deg)",
          position: "absolute",
        }}
        src={getWebpImageUrl("Dice")}
        alt="A descriptive alt text"
      />
      <div
        style={{
          top: "170px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
        }}
      >
        <p
          style={{
            fontSize: "3.5rem",
            fontWeight: 600,
            lineHeight: "3rem",
          }}
        >
          {props.pageName} Page
        </p>
        <p
          style={{
            fontSize: "1.875rem",
            fontWeight: 500,
            lineHeight: "1.938rem",
            textAlign: "center",
            marginTop: 0,
          }}
        >
          Something big is coming soon...
        </p>
      </div>
      <WebPImage
        style={{
          width: "341px",
          height: "341px",
          top: "448px",
          left: "-133px",
          transform: "rotate(-4.82deg)",
          position: "absolute",
        }}
        src={getWebpImageUrl("Poker")}
        alt="A descriptive alt text"
      />
      <WebPImage
        style={{
          width: "341px",
          height: "341px",
          top: "371px",
          left: "113.68px",
          transform: "rotate(-4.82deg)",
          position: "absolute",
        }}
        src={getWebpImageUrl("Slots")}
        alt="A descriptive alt text"
      />
    </Content>
  );
};

export default SoonBanner;
